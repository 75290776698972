import './style.css';
import { Map, View } from 'ol';
import GeoJSON from 'ol/format/GeoJSON.js';
import { Icon, Stroke, Style } from 'ol/style.js';
import { Vector as VectorSource } from 'ol/source.js';
import { Vector as VectorLayer } from 'ol/layer.js';
import Overlay from 'ol/Overlay.js';
import StyleProfile from 'ol-ext/style/Profile.js';
import { get as getProjection } from 'ol/proj.js';
import {
  DragRotate,
  defaults as defaultInteractions,
} from 'ol/interaction.js';
import { MapboxVectorLayer } from 'ol-mapbox-style';
import { Point } from 'ol/geom.js';
import Feature from 'ol/Feature.js';
import { Heatmap as HeatmapLayer } from 'ol/layer.js';
import { none } from 'ol/centerconstraint';






/**
 * GLOBAL VARIABLES and INTERFACE ELEMENTS
*/

//The extent of the map to show
const extent = [669894.1159, 5804016.4318, 783326.6659, 5874032.7497];
const extentMax = [610273.2338, 5766409.4138, 856094.7168, 5939462.8459];

//The currently selected feature and its color
let selectedFeature = [];
let selectedColor = [];

//The currently visible features
let visibleFeatures = [];

//The currently highlighted features and their colors (highlighted click over the attribute table)
let hoveredFeatures = [];

//Show time as altitude
let showTimeAsAlt = false;
const showTimeAsAltCB = document.getElementById('showTimeAsAltCB');

if (showTimeAsAltCB) {
  showTimeAsAltCB.checked = false;
}


//Show the traces of the boats
let showTraces = true;
const showTracesCB = document.getElementById('showTracesCB');
showTracesCB.checked = true;

//The slider to show boats at time and its time indicator
const timeAfterStartSlider = document.getElementById('timeAfterStartSlider');
const timeAfterStartLabel = document.getElementById('timeAfterStartOutput');
const playButton = document.getElementById('playButton');
const playButtonIcon = document.getElementById('playButtonIcon');

//The attribute table
const attTableBody = document.getElementById("attTableBody");
const attTableDiv = document.getElementById("attTableDiv");
const attTable = document.getElementById('attTable');
const attDragbar = document.getElementById("attDragbar");

//The menu button
const menuButton = document.getElementById('menuButton');

//The filter summary text appliedFiltersDiv and Text
const appliedFiltersDiv = document.getElementById('appliedFiltersDiv');
const appliedFiltersText = document.getElementById('appliedFiltersText');

//The page wrapper
const page = document.getElementById('page');

//The menuControls
const menuDiv = document.getElementById('menuDiv');
const unselectButton = document.getElementById('unselectButton');

//Popup
const popupContainer = document.getElementById('popup');
const popupContent = document.getElementById('popup-content');

//Heatmap
const blur = document.getElementById('blur');
const radius = document.getElementById('radius');
const toggleHeatMap = document.getElementById('toggleHeatMap');

















/**
 * SETTINGS AND STYLES
 */

//Style for the features

const defaultStyles = function (feature) {
  const selIndex = selectedFeature.indexOf(feature);
  const isSelected = selIndex >= 0;
  const isHovered = hoveredFeatures.indexOf(feature) >= 0;
  const isVisible = visibleFeatures.indexOf(feature) >= 0;

  //If the feature is not visible, return an empty style
  if (!isVisible) {
    return new Style({});
  }

  if (isHovered) {
    //If the feature is hovered, return a halo style
    return haloStyleGenerator();
  } else if (isSelected) {
    if (showTimeAsAlt) { //If showTimeAsAlt is true, return a StyleProfile
      return new StyleProfile({
        stroke: new Stroke({
          color: selectedColor[selIndex],
          width: 4,
        }),
        zIndex: 1,
        scale: .002,
      });
    } else {
      //If the feature is selected, return a selected style
      return new Style({
        stroke: new Stroke({
          color: selectedColor[selIndex],
          width: 4,
        }),
        zIndex: 1,
      });
    }
  } else if (showTraces) {
    //If showTraces is true, return a simple default style
    return new Style({
      stroke: new Stroke({
        color: '#00A300',
        width: 1.5,
      }),
    });
  } else if (!showTraces) {
    //If showTraces is false, return an empty style
    return new Style({});
  }

}

//The style for the highlihted features 
function haloStyleGenerator() {
  const steps = 5;
  let haloStyle = [];
  for (let i = 0; i < steps; i++) {
    haloStyle.push(
      new Style({
        stroke: new Stroke({
          color: [255, 255, 255, 1 / (steps - i)],
          width: (steps - i) * 2 - 1
        }),
        zIndex: 2,
      })
    );
  };
  return haloStyle;
}

/**
 * Rotate and select (pop-up) interactions for the map
 */
let interactions = defaultInteractions({
  shiftDragZoom: false
});
interactions.push(new DragRotate({
  //duration: 200,
  condition: function (mapBrowserEvent) {
    let originalEvent = mapBrowserEvent.originalEvent;
    return (
      (originalEvent.ctrlKey || originalEvent.shiftKey || originalEvent.altKey) &&
      !(originalEvent.metaKey));
  }
}));

//Create an overlay to anchor the popup to the map
const overlay = new Overlay({
  element: popupContainer,
});











/**
 * DATA
 */

//The vector source for the boldor data
let vectorSource = new VectorSource({
  projection: 'EPSG:3857',
  url: './res/boldor_min.geojson',
  format: new GeoJSON(),

});
vectorSource.set('olcs.projection', getProjection('EPSG:3857'));
//The vector layer for the boldor data
const vectorLayer = new VectorLayer({
  source: vectorSource,
  style: defaultStyles,
});
















/**
 * CREATE THE MAP
 */
const map = new Map({
  target: 'map',
  layers: [
    // 
    new MapboxVectorLayer({
      styleUrl: 'mapbox://styles/flavienrouiller/clokmtwvn008501qobfca5mex',
      accessToken:
        'pk.eyJ1IjoiZmxhdmllbnJvdWlsbGVyIiwiYSI6ImNscW12anpnZDBhNnAycXNiam50NTJqN3QifQ.MzSw7XiT2CajgnPag5VB-Q',
    }),
    vectorLayer,
  ],
  projection: 'EPSG:3857',
  overlays: [overlay],
  view: new View({
    center: [724689.88, 5844682.85],
    zoom: 11,
    extent: extentMax,
    showFullExtent: true,
  }),
  interactions: interactions,
});

//Fit the map to the extent (screen size dependent)
map.getView().fit(extent, map.getSize());

/**
 * When data is loaded, populate the dropdowns and set the slider min and max values
 */
let years = [];
let series = [];
let sliderMin = Infinity;
let sliderMax = -Infinity;
vectorSource.on('featuresloadend', function () {
  vectorSource.forEachFeature(function (feature) {
    // get all years in the data
    let year = feature.get('annee');
    if (years.indexOf(year) === -1) {
      years.push(year);
    }

    //get all series in the data
    let serie = feature.get('serie_avec_autres');
    if (series.indexOf(serie) === -1) {
      series.push(serie);
    }

    //get the min and max values for the slider
    let geom = feature.getGeometry();
    let coords = geom.getCoordinates();

    if (coords[0][2] < sliderMin) {
      sliderMin = coords[0][2];
    }
    if (coords.at(-1)[2] > sliderMax) {
      sliderMax = coords.at(-1)[2];
    }


  })
  years.sort();
  series.sort();

  // add the years to the dropdown
  let yearDropdown = document.getElementById('yearDropdown');
  //Add an all years option
  // let option = document.createElement('option');
  // option.text = 'All years';
  // option.value = 'all';
  // yearDropdown.add(option);
  //Add the other years
  years.forEach((year) => {
    let option = document.createElement('option');
    option.text = year;
    yearDropdown.add(option);
  });

  //Select the last year
  yearDropdown.selectedIndex = yearDropdown.length - 1;

  // add the series to the dropdown
  let seriesDropdown = document.getElementById('seriesDropdown');
  //Add an all series option
  let option2 = document.createElement('option');
  option2.text = 'Toutes les séries';
  option2.value = 'all';
  seriesDropdown.add(option2);
  //Add the other series
  series.forEach((serie) => {
    let option = document.createElement('option');
    option.text = serie;
    seriesDropdown.add(option);
  });

  //initialize the filters
  setFilters();

  //Set the slider min and max values
  timeAfterStartSlider.min = sliderMin;
  timeAfterStartSlider.max = sliderMax;
  timeAfterStartSlider.value = sliderMin;
  timeAfterStartLabel.innerHTML = secondsToHms(sliderMin);
  //Show boats at time
  showBoatsAtTime(timeAfterStartSlider.value);
});














/*
* FILTERS
*/

// Listen for changes in the select element with id 'yearDropdown'
let yearDropdown = document.getElementById('yearDropdown');
yearDropdown.addEventListener('change', function () {
  setFilters();
  showBoatsAtTime(timeAfterStartSlider.value);
});

// Listen for changes in the select element with id 'seriesDropdown'
let seriesDropdown = document.getElementById('seriesDropdown');
seriesDropdown.addEventListener('change', function () {
  setFilters();
  showBoatsAtTime(timeAfterStartSlider.value);
});

// Listen for changes in the select element with id 'allerRetourDropdown'
let allerRetourDropdown = document.getElementById('allerRetourDropdown');
allerRetourDropdown.addEventListener('change', function () {
  setFilters();
  showBoatsAtTime(timeAfterStartSlider.value);
});

// Listen for changes in the input element with id 'maxRankSerie'
let maxRankSerieInput = document.getElementById('maxRankSerie');
maxRankSerieInput.addEventListener('change', function () {
  setFilters();
  showBoatsAtTime(timeAfterStartSlider.value);
});


/**
 * Function to set the filters
 */
function setFilters() {
  let year = yearDropdown.value;
  let serie = seriesDropdown.value;
  let allerRetour = allerRetourDropdown.value;
  let maxRankSerie = maxRankSerieInput.value;

  let filters = [];
  if (year !== 'all') {
    filters.push(['==', 'annee', Number(year), '']);
  }
  if (serie !== 'all') {
    filters.push(['==', 'serie_avec_autres', serie, '']);
  }
  if (allerRetour !== 'all') {
    filters.push(['==', 'aller_retour', allerRetour, '']);
  }
  if (maxRankSerie !== '') {
    filters.push(['<=', 'rang_serie_annee', Number(maxRankSerie), 'Rang max: ']);
  }

  visibleFeatures = [];
  vectorSource.forEachFeature(function (feature) {
    //evaluate against the filters

    let show = true;
    filters.forEach(function (filter) {
      if (filter[0] === '==' && (!feature.get(filter[1]) || feature.get(filter[1]) !== filter[2])) {
        show = false;
      }
      else if (filter[0] === '<=' && (!feature.get(filter[1]) || feature.get(filter[1]) > filter[2])) {
        show = false;
      }


    });
    if (show) {
      //feature.setStyle(defaultStyles);
      visibleFeatures.push(feature);
    }
    feature.setStyle(defaultStyles);
    // else {feature.setStyle(new Style({}));}
  });

  //Update the attribute table
  updateAttTable();
  //hideShowAttTablePlaceholder();
  //updateCounters();
  updateHeatmap();
  updateAppliedFiltersText(filters);
};

/*
* Function to update the counters of boats visibles and selected
*/
function updateCounters() {
  let visibleCounter = document.getElementById('visBoatCount');
  let selectedCounter = document.getElementById('selectedBoatCount');

  //Get the number of visible features, but count only one feature per boat
  let uniqueFeatures = [];
  visibleFeatures.forEach(function (feature) {
    let nom_bateau = feature.get('nom_bateau');
    let unique = uniqueFeatures.find(function (uniqueFeature) {
      return uniqueFeature.get('nom_bateau') === nom_bateau;
    });
    if (!unique) {
      uniqueFeatures.push(feature);
    }
  });

  visibleCounter.innerHTML = uniqueFeatures.length;
  selectedCounter.innerHTML = selectedFeature.length / 2;

  //Get the number of rows in the attribute table
  let attTableRows = attTableBody.rows.length;
  let visSelCounter = document.getElementById('visSelBoatCount');
  visSelCounter.innerHTML = attTableRows;
};

/*
* Function to update the filter text (id appliedFiltersText) based on the filters given as input
*/
function updateAppliedFiltersText(filters) {
  //Get the appliedFiltersText element
  let appliedFiltersText = document.getElementById('appliedFiltersText');
  //Clear the text
  appliedFiltersText.innerHTML = 'Filtres appliqués: ';
  //Add the text for each filter
  filters.forEach(function (filter) {
    let text = filter[3] + filter[2];
    appliedFiltersText.innerHTML += text;
    //If it's not the last element, add a space
    if (filter !== filters[filters.length - 1]) {
      appliedFiltersText.innerHTML += ' - ';
    }

  });
}
















/**
 * SELECT FEATURES
 */

map.on('singleclick', function (evt) {



  closePopup();
  hoveredFeatures = [];

  //Get the feature at the clicked location
  const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
    const selIndex = selectedFeature.indexOf(feature);
    if (selIndex < 0) {//Feature not selected yet

      //Add the feature to selectedFeature
      addFeatureToSelectedList(feature);
      //Populate att table
      loadTableData(feature);

    } else { //Feature already selected -> unselect it

      //Remove the feature from selectedFeature
      removeFeatureFromSelectedList(feature);
      //Remove row from table
      removeRowFromAttTable(feature);


    }

    return feature;
  },
    {//Only select features from the vector layer
      layerFilter: function (layer) {
        return layer === vectorLayer;
      },
      hitTolerance: 1,
    }
  );

  if (feature) {
    //Hide or show attTablePlaceholder
    hideShowAttTablePlaceholder();
    //redraw the boats at time layer
    showBoatsAtTime(timeAfterStartSlider.value);
    //Stop the highlight of the feature


  }
});

/*
* Unselect button
*/
unselectButton.addEventListener('click', function () {
  //if selectedFeature is not empty, clear it
  if (selectedFeature.length > 0) {
    //Clear selectedFeature
    selectedFeature = [];
    selectedColor = [];
    //Clear the attribute table
    updateAttTable();
    //redraw the boats at time layer
    showBoatsAtTime(timeAfterStartSlider.value);
    //update the style of the features
    visibleFeatures.forEach(function (feature) {
      feature.setStyle(defaultStyles);
    });

    //Deactivate the unselect button
    unselectButton.disabled = true;
  }
});












/*
* HOVER OVER FEATURES ON THE MAP
*/

//Listen for mouse moves on the map and display the popup
let LastTimeoutRequest;

function DisplayInfo(feature, coordinate, mousePos) {

  //Populate popup
  let annee = feature.get('annee');
  let serie = feature.get('serie');
  let aller_retour = feature.get('aller_retour');
  let nom_bateau = feature.get('nom_bateau');
  let rang_serie = feature.get('rang_serie_annee');
  let rang_global = feature.get('rang_global');
  let rang = rang_serie + "/" + rang_global;

  let description = "<h3>" + nom_bateau + "</h3><p>" + annee + " - " + aller_retour + "</p><p>Série: " + serie + "</p><p>Rang dans série / global: " + rang + "</p>";

  popupContent.innerHTML = description;

  //Get the size of element with id map
  let mapSize = document.getElementById('map').getBoundingClientRect();

  // Determine the quadrant of the mouse position
  let quadrant = null;
  let offset = null;
  if (mousePos[0] < mapSize.width / 2 && mousePos[1] < mapSize.height / 2) {
    quadrant = 'top-left';
    offset = [10, 10];
  } else if (mousePos[0] > mapSize.width / 2 && mousePos[1] < mapSize.height / 2) {
    quadrant = 'top-right';
    offset = [-10, 10];
  } else if (mousePos[0] < mapSize.width / 2 && mousePos[1] > mapSize.height / 2) {
    quadrant = 'bottom-left';
    offset = [10, -10];
  } else if (mousePos[0] > mapSize.width / 2 && mousePos[1] > mapSize.height / 2) {
    quadrant = 'bottom-right';
    offset = [-10, -10];
  }

  // Set the positioning of the popup
  overlay.setPositioning(quadrant);
  overlay.setOffset(offset);


  //Set the position of the popup
  overlay.setPosition(coordinate);


  //Highlight the trace of the feature
  hoveredFeatures.push(feature);
  feature.setStyle(defaultStyles);
}

map.on('pointermove', function (evt) {
  if (evt.dragging) {
    return;
  }

  if (typeof LastTimeoutRequest !== 'undefined') { // cancel timeout when the mouse moves
    clearTimeout(LastTimeoutRequest);
    closePopup();
  }

  //Get the feature at the hovered location
  const feature = map.forEachFeatureAtPixel(evt.pixel, function (feature) {
    hoveredFeatures = [];
    feature.setStyle(defaultStyles);
    return feature;
  },
    {//Only select features from the vector layer
      layerFilter: function (layer) {
        return layer === vectorLayer;
      }, hitTolerance: 1,
    }
  );


  if (feature) {
    const coordinate = evt.coordinate;
    LastTimeoutRequest = setTimeout(function () {
      DisplayInfo(feature, coordinate, evt.pixel);
    }, 750);
  }
});

//Add a click handler to hide the popup
// closer.onclick = function () {
//   overlay.setPosition(undefined);
//   closer.blur();
//   return false;
// };
//Function to close the popup
function closePopup() {
  overlay.setPosition(undefined);
  // closer.blur();
};











/*
* ATTRIBUTE TABLE
*/

//Populate
function loadTableData(feature) {

  //Check if the boat is already in the table
  let nom_bateau = feature.get('nom_bateau');
  let annee = feature.get('annee');
  let serie = feature.get('serie');



  let rowExist = Array.from(attTableBody.rows).find(function (row) {
    return row.cells[0].innerHTML === nom_bateau && row.cells[1].innerHTML === serie && row.cells[2].innerHTML == annee;
  });

  if (!rowExist) {//If the row does not exist yet, add it to the table



    let selIndex = selectedFeature.indexOf(feature);

    let row = attTableBody.insertRow();

    let cellNomBat = row.insertCell(0);
    cellNomBat.innerHTML = feature.get('nom_bateau');

    let cellSeries = row.insertCell(1);
    cellSeries.innerHTML = feature.get('serie');

    let cellYear = row.insertCell(2);
    cellYear.innerHTML = feature.get('annee');

    let cellRangSerie = row.insertCell(3);
    cellRangSerie.innerHTML = feature.get('rang_serie_annee');

    let cellRangGen = row.insertCell(4);
    cellRangGen.innerHTML = feature.get('rang_global');

    let cellColor = row.insertCell(5);
    //Get the color of the selected feature
    let color = selectedColor[selIndex];
    //cellColor.innerHTML = "<div class='colorBox' style='background-color:" + color + "'></div>";
    cellColor.style.backgroundColor = color;

    let cellRemove = row.insertCell(6);
    //add a fontawsome icon to the cell
    let icon = document.createElement('i');
    icon.classList.add('fas');
    icon.classList.add('fa-times');
    icon.classList.add('icon');
    //Change the mouse when hovering over the icon
    icon.style.cursor = 'pointer';

    cellRemove.appendChild(icon);
    //add a click listener to the icon
    icon.addEventListener('click', function () {
      //Remove the feature from selectedFeature
      removeFeatureFromSelectedList(feature);

      //Remove the row
      removeRowFromAttTable(feature);
    });

    //Scroll to the bottom of the table
    attTableDiv.scrollTop = attTableDiv.scrollHeight;
  } else {
    console.log('row already exists');
  }

  //Update counters
  updateCounters();

};

/*
* Function to update the content of the attribute table based on the selected features and visible features
*/
function updateAttTable() {
  //Clear the table
  attTableBody.innerHTML = '';

  //Add a row for each selected feature
  selectedFeature.forEach(function (feature) {
    //if the feature is visible, add it to the table
    let visIndex = visibleFeatures.indexOf(feature);
    if (visIndex >= 0) {
      loadTableData(feature);
    }
  });
  //Hide or show attTablePlaceholder
  hideShowAttTablePlaceholder();
  updateCounters();
};


/*
* Function to remove a row from the attribute table based on a feature
*/
function removeRowFromAttTable(feature) {
  //Get the name, year, serie and aller_retour of the feature
  let nom_bateau = feature.get('nom_bateau');
  let annee = feature.get('annee');
  let serie = feature.get('serie');

  //Find the row that matches the feature
  let row = Array.from(attTableBody.rows).find(function (row) {
    return row.cells[0].innerHTML === nom_bateau &&
      row.cells[1].innerHTML === serie &&
      row.cells[2].innerHTML == annee
  });

  //Remove the row
  attTableBody.deleteRow(row.rowIndex - 1);

  //Update counters
  updateCounters();
};


/*
* Attribute table dragbar for resize
*/

const BORDER_SIZE = 10;
const MIN_TABLE_SIZE = 15;

let m_pos;

function resize(e) {

  let page = document.getElementById("page");

  const dy = m_pos - e.y;
  m_pos = e.y;

  let targetHeight = parseInt(getComputedStyle(attTableDiv, '').height) + dy;
  if (targetHeight < MIN_TABLE_SIZE) {
    targetHeight = MIN_TABLE_SIZE;
    document.removeEventListener("mousemove", resize, false);
    map.updateSize();
  }
  let rows = [
    56,
    page.clientHeight - BORDER_SIZE - targetHeight - 56,
    BORDER_SIZE,
    targetHeight,
  ];

  let newRowsDefn = rows.map(c => c.toString() + "px").join(" ");
  page.style.gridTemplateRows = newRowsDefn;
  e.preventDefault()
}

attDragbar.addEventListener("pointerdown", function (e) {

  m_pos = e.y;
  document.addEventListener("pointermove", resize, false);

}, false);

attDragbar.addEventListener("pointerup", function () {
  document.removeEventListener("pointermove", resize, false);
  map.updateSize();

}, false);


/**
 * Hover over the attribute table
 */

//Listen to mouseenter
attTable.addEventListener('pointerover', function (e) {

  //Get the row that is hovered
  let row = e.target.closest('tr');
  if (row.rowIndex > 0) {//If the row is not the header row

    hoveredFeatures = [];

    //Find the feature that matches the row
    let nom_bateau = row.cells[0].innerHTML;
    let annee = row.cells[2].innerHTML;
    let serie = row.cells[1].innerHTML;

    hoveredFeatures.push(findFeature(nom_bateau, annee, serie, 'aller'));
    hoveredFeatures.push(findFeature(nom_bateau, annee, serie, 'retour'));

    //Change the style of the feature
    hoveredFeatures.forEach(function (feature) {
      if (feature) {
        feature.setStyle(defaultStyles);
      }

    });


  }
});

//Listen to mouseleave
attTable.addEventListener('pointerout', function (e) {



  if (hoveredFeatures) {
    let oldHoveredFeatures = hoveredFeatures;

    //Reset the hoveredFeatures
    hoveredFeatures = [];

    //Reset the style of the previously hovered features
    oldHoveredFeatures.forEach(function (feature) {
      if (feature) {
        feature.setStyle(defaultStyles);
      }
    });


  }

});












/*
* SLIDER for boat animation
*/

//Listen for changes in the input element with id 'timeAfterStartSlider'

timeAfterStartSlider.addEventListener('input', function () {
  //get the value of the slider
  let time = timeAfterStartSlider.value;
  showBoatsAtTime(time);

  //Update the value of the label with id 'timeAfterStartOutput'
  timeAfterStartLabel.innerHTML = secondsToHms(time);
});

//Show boats at time
function showBoatsAtTime(time) {
  //Get all features from the vector source that are visible
  let features = vectorSource.getFeatures().filter(function (feature) {
    let visIndex = visibleFeatures.indexOf(feature);
    return visIndex >= 0;
  });


  //get the coordinates of the features at the given time
  let pointFeatures = [];
  features.forEach(function (feature) {
    let coordAndBearingAtTime = getCoordAndBearingAtTime(feature, time);

    if (coordAndBearingAtTime) {//If the feature has a coordinate at the given time, add it to the pointFeatures
      let coordAtTime = coordAndBearingAtTime[0];
      let bearing = coordAndBearingAtTime[1];
      let pointFeature = new Feature({
        geometry: new Point(coordAtTime),
      });

      let style;

      //Find if the feature is selected
      let selIndex = selectedFeature.indexOf(feature);
      if (selIndex >= 0) {
        //If the feature is selected, use the selection color for the icon
        style = new Style({
          image: new Icon({
            src: './res/bateau_simple.svg',
            color: selectedColor[selIndex],
            rotation: bearing,
            scale: 1.4,
          }),
        });
      } else {
        //If the feature is not selected, use the default color for the icon
        style = new Style({
          image: new Icon({
            src: './res/bateau_simple.svg',
            color: '#8D89A6',
            rotation: bearing,
          }),
        });
      }

      pointFeature.setStyle(style);
      pointFeatures.push(pointFeature);
    }
  });

  let pointSource = new VectorSource({
    features: pointFeatures
  });


  //Remove the previous point layer
  map.getLayers().forEach(function (layer) {
    if (layer && layer.get('name') === 'boatsAtTimeLayer') {
      map.removeLayer(layer);
    }
  }
  );

  //Add the new point layer
  let pointLayer = new VectorLayer({
    source: pointSource,
    name: 'boatsAtTimeLayer',
  });
  map.addLayer(pointLayer);
};

/*
*Animate the slider with play button
*/

let sliderInterval;
let sliderIntervalRunning = false;
let sliderIntervalStepSize = Number(timeAfterStartSlider.step) * 3;
let sliderIntervalSpeed = 1000;


// Listen for clikcs on the play button
playButton.addEventListener('click', function () {
  let sliderIntervalStart = timeAfterStartSlider.value;
  let sliderIntervalEnd = sliderMax;
  let sliderIntervalCurrent = sliderIntervalStart;

  //If the slider is not running, start it
  if (!sliderIntervalRunning) {
    sliderIntervalRunning = true;
    //Change the icon of the button to pause
    playButtonIcon.classList.remove('fa-play');
    playButtonIcon.classList.add('fa-pause');

    //disable interaction with the slider
    timeAfterStartSlider.disabled = true;


    //Start the slider
    sliderInterval = setInterval(function () {
      //If the slider is at the end, stop it
      if (sliderIntervalCurrent === sliderIntervalEnd) {
        stopSlider();
      } else {
        //Increment the slider
        sliderIntervalCurrent = Number(sliderIntervalCurrent) + Number(sliderIntervalStepSize);
        //Update the slider
        timeAfterStartSlider.value = sliderIntervalCurrent;
        timeAfterStartLabel.innerHTML = secondsToHms(sliderIntervalCurrent);
        //Update the boats
        showBoatsAtTime(sliderIntervalCurrent);
      }
    }, sliderIntervalSpeed);
  } else {//If the slider is running, stop it
    stopSlider();
  }
});

//Function to stop the slider
function stopSlider() {
  //Stop the slider
  clearInterval(sliderInterval);
  sliderIntervalRunning = false;
  //Change the icon of the button to play
  playButtonIcon.classList.remove('fa-pause');
  playButtonIcon.classList.add('fa-play');

  //enable interaction with the slider
  timeAfterStartSlider.disabled = false;

};








/*
* SEARCH BOX
*/
//Listen for changes in the input element with id 'searchBox'
const searchBox = document.getElementById('searchBox');

searchBox.addEventListener('input', function () {
  //Get the value of the searchBox
  let searchValue = searchBox.value;
  //Get all features from the vector source that are visible
  let features = vectorSource.getFeatures().filter(function (feature) {
    let visIndex = visibleFeatures.indexOf(feature);
    return visIndex >= 0;
  });

  //Remove boats with the same name from the features
  let uniqueFeatures = [];
  features.forEach(function (feature) {
    let nom_bateau = feature.get('nom_bateau');
    let unique = uniqueFeatures.find(function (uniqueFeature) {
      return uniqueFeature.get('nom_bateau') === nom_bateau;
    });
    if (!unique) {
      uniqueFeatures.push(feature);
    }
  });
  features = uniqueFeatures;


  //Get the features that match the search value
  let matchingFeatures = features.filter(function (feature) {
    let nom_bateau = feature.get('nom_bateau');

    let searchValueLower = searchValue.toLowerCase();
    return (nom_bateau.toLowerCase().includes(searchValueLower));
  });

  //If the content of the searchBox is the same as the value of a feature, select it
  if (matchingFeatures.length === 1 && matchingFeatures[0].get('nom_bateau').toLowerCase() === searchValue.toLowerCase()) {
    let feature = matchingFeatures[0];
    let selIndex = selectedFeature.indexOf(feature);
    if (selIndex < 0) {//Feature not selected yet
      addFeatureToSelectedList(feature);

      //Populate att table
      loadTableData(feature);

      //Clear the searchResults
      let datalist = document.getElementById('searchBoxResults');
      datalist.innerHTML = '';

    } else if (selIndex >= 0) { //Feature already selected -> do nothing
    }




  } else if (matchingFeatures.length > 0) {

    //Add a datalist of the matching features below the searchBox
    let datalist = document.getElementById('searchBoxResults');
    datalist.innerHTML = '';
    matchingFeatures.forEach(function (feature) {
      let option = document.createElement('option');
      option.value = feature.get('nom_bateau');
      datalist.appendChild(option);

    });

  } else {
    //Add a message to the datalist to inform of no results
    let datalist = document.getElementById('searchBoxResults');
    datalist.innerHTML = '';
    let option = document.createElement('option');
    option.textContent = 'Pas de resultats';
    //option.disabled = true;
    option.value = 'Pas de resultats';
    datalist.appendChild(option);
  }

}
);






















/*
 * ABOUT MODAL
 */

document.addEventListener('DOMContentLoaded', () => {
  // Functions to open and close a modal
  function openModal(el, scroll) {
    el.classList.add('is-active');
    console.log(scroll);
    if (scroll) { scroll.scrollIntoView(); }
    else { headerModal.scrollIntoView(); }
  }

  function closeModal(el) {
    el.classList.remove('is-active');
  }

  function closeAllModals() {
    (document.querySelectorAll('.modal')).forEach((modal) => {
      closeModal(modal);
    });
  }

  // Add a click event on buttons to open a specific modal
  (document.querySelectorAll('.js-modal-trigger')).forEach((trigger) => {
    const modal = trigger.dataset.target;
    const scrollAnchorName = trigger.dataset.scroll;
    const scroll = document.getElementById(scrollAnchorName);

    const target = document.getElementById(modal);

    trigger.addEventListener('click', () => {
      openModal(target, scroll);
    });
  });

  // Add a click event on various child elements to close the parent modal
  (document.querySelectorAll('.modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button') || []).forEach((close) => {
    const target = close.closest('.modal');

    close.addEventListener('click', () => {
      closeModal(target);
    });
  });

  // Add a keyboard event to close all modals
  document.addEventListener('keydown', (event) => {
    if (event.code === 'Escape') {
      closeAllModals();
    }
  });
});










/*
* SHOW TIME AS ALTITUDE
*/

//Function to toggle showTimeAsAlt when the checkbox is clicked
//Get the state of the checkbox
function updateShowTimeAsAlt() {
  //let showTimeAsAltCB = document.getElementById('showTimeAsAltCB');
  showTimeAsAlt = showTimeAsAltCB.checked;
  //Change the style of the selected features, but keep the color
  selectedFeature.forEach(function (feature) {
    feature.setStyle(defaultStyles);
  });
}
//On click, update the state of the checkbox

if (showTimeAsAltCB) {
  showTimeAsAltCB.addEventListener('click', updateShowTimeAsAlt);
}












/*
* SHOW TRACES
*/
//Listen for changes in the input element with id 'showTracesCB' and update the style of the features
showTracesCB.addEventListener('click', function () {
  showTraces = showTracesCB.checked;
  vectorSource.forEachFeature(function (feature) {
    feature.setStyle(defaultStyles);
  });
});














/*
* HEATMAP
*/

let heatmapLayer = none;
//Set the heatmapActive to false
let heatmapActive = false;
blur.disabled = true;
radius.disabled = true;
toggleHeatMap.checked = false;

//Liste for change in button to toggle heatmap with id toggleHeatMap
toggleHeatMap.addEventListener('click', function () {
  console.log('toggleHeatMap clicked');
  //If the heatmap is not active, activate it
  if (!heatmapActive) {

    //Set the heatmapActive to true
    heatmapActive = true;

    //Activate the heatmap sliders
    blur.disabled = false;
    radius.disabled = false;

    //Update the heatmap
    updateHeatmap();



  } else {//If the heatmap is active, deactivate it

    //Set the heatmapActive to false
    heatmapActive = false;

    //Deactivate the heatmap sliders
    blur.disabled = true;
    radius.disabled = true;

    //Update the heatmap
    updateHeatmap();

  }
});

//For finding blur and radius values

blur.value = 15;
radius.value = 5;

function updateHeatmap() {
  //If the heatmap is active, update it
  if (heatmapActive) {
    //Remove the heatmap layer
    map.removeLayer(heatmapLayer);
    //Create the heatmap layer
    heatmapLayer = createHeatmapLayer();
    //Add the heatmap layer
    map.addLayer(heatmapLayer);
  }
  //If the heatmap is not active, remove it
  else {
    //Remove the heatmap layer
    map.removeLayer(heatmapLayer);
  }

}

//Function to create the heatmap layer
function createHeatmapLayer() {
  //Get all features from the vector source that are visible
  let features = vectorSource.getFeatures().filter(function (feature) {
    let visIndex = visibleFeatures.indexOf(feature);
    return visIndex >= 0;
  });

  //Get a point every 100 meters on on the line
  let coords = [];
  features.forEach(function (feature) {
    let geom = feature.getGeometry();
    let featureCoords = geom.getCoordinates();


    //  Add the last coordinate
    let firstPointFeature = new Feature({
      geometry: new Point(featureCoords.at(0)),
    });
    coords.push(firstPointFeature);

    let lastCoord = featureCoords[0];
    let distance = 0;
    featureCoords.forEach(function (coord, index) {
      distance = distance + getDistance(lastCoord[0], lastCoord[1], coord[0], coord[1]);
      if (distance > 1500) {
        let pointFeature = new Feature({
          geometry: new Point(coord),
        });
        coords.push(pointFeature);
        distance = 0;
      }

      lastCoord = coord;
    });
    //  Add the last coordinate
    let lastPointFeature = new Feature({
      geometry: new Point(featureCoords.at(-1)),
    });
    coords.push(lastPointFeature);
  });

  //Create the heatmap layer
  let heatmapLayer = new HeatmapLayer({
    source: new VectorSource({
      features: coords,
    }),
    blur: parseInt(blur.value, 10),
    radius: parseInt(radius.value, 10),
    weight: function (feature) { return 0.5 },
  });
  return heatmapLayer;
}

blur.addEventListener('input', function () {
  heatmapLayer.setBlur(parseInt(blur.value, 10));
});

radius.addEventListener('input', function () {
  heatmapLayer.setRadius(parseInt(radius.value, 10));
});


















/*
* HELPER FUNCTIONS
*/

//Function to generate a random color
function getRandomColor_old() {

  let letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 3; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;

};

function getRandomColor() {
  let colors = {
    aqua: "#00ffff",
    azure: "#f0ffff",
    beige: "#f5f5dc",
    black: "#000000",
    blue: "#0000ff",
    brown: "#a52a2a",
    cyan: "#00ffff",
    darkblue: "#00008b",
    darkcyan: "#008b8b",
    darkgrey: "#a9a9a9",
    //darkgreen: "#006400",
    darkkhaki: "#bdb76b",
    darkmagenta: "#8b008b",
    darkolivegreen: "#556b2f",
    darkorange: "#ff8c00",
    darkorchid: "#9932cc",
    darkred: "#8b0000",
    darksalmon: "#e9967a",
    darkviolet: "#9400d3",
    fuchsia: "#ff00ff",
    gold: "#ffd700",
    //green: "#008000",
    indigo: "#4b0082",
    khaki: "#f0e68c",
    lightblue: "#add8e6",
    lightcyan: "#e0ffff",
    //lightgreen: "#90ee90",
    lightgrey: "#d3d3d3",
    lightpink: "#ffb6c1",
    lightyellow: "#ffffe0",
    lime: "#00ff00",
    magenta: "#ff00ff",
    maroon: "#800000",
    navy: "#000080",
    olive: "#808000",
    orange: "#ffa500",
    pink: "#ffc0cb",
    purple: "#800080",
    violet: "#800080",
    red: "#ff0000",
    silver: "#c0c0c0",
    //white: "#ffffff",
    yellow: "#ffff00"
  };

  //Removes the colors that are already used, if there are less color in use than the total number of colors
  let usedColors = selectedColor;
  let availableColors = Object.values(colors).filter(function (color) {
    return !usedColors.includes(color);
  });
  //if availableColors is empty, use the full list of colors
  if (availableColors.length === 0) {
    availableColors = Object.values(colors);
  }
  //get a random color from the available colors
  let color = availableColors[Math.floor(Math.random() * availableColors.length)];

  return color;
}


//Function to hide or show attTablePlaceholder

function hideShowAttTablePlaceholder() {
  const attTablePlaceholder = document.getElementById('attTablePlaceholder');
  const attTable = document.getElementById('attTable');
  const isEmpty = attTableBody.rows.length === 0;

  attTablePlaceholder.style.display = isEmpty ? 'block' : 'none';
  attTable.style.marginBottom = isEmpty ? '24px' : '0px';
}


//Function to return coordinates of a feature where the [2] is closest to the given value. [2] is ordered smallest to largest
function getCoordAndBearingAtTime(feature, time) {
  let geom = feature.getGeometry();
  let coords = geom.getCoordinates();
  let closestCoord = coords[0];
  let closestDiff = Math.abs(coords[0][2] - time);
  let bearing = null;
  for (let i = 1; i < coords.length; i++) {
    let diff = Math.abs(coords[i][2] - time);
    if (diff <= closestDiff) {
      closestDiff = diff;
      closestCoord = coords[i];
      if (i < coords.length - 1) {
        bearing = getBearing(coords[i][0], coords[i][1], coords[i + 1][0], coords[i + 1][1]);
      } else {
        bearing = getBearing(coords[i - 1][0], coords[i - 1][1], coords[i][0], coords[i][1]);
      }
    } else { break; }
  }
  if (closestDiff < (60 * 5)) {//If the closest time is less than 5 minutes away, return the coordinates
    return [closestCoord, bearing];
  } else {
    return null;
  }
}

// Get the bearing between two coordinates
function getBearing(startLong, startLat, endLong, endLat) {
  const dLong = endLong - startLong;
  const dLat = endLat - startLat;
  return Math.atan2(dLong, dLat); //in radians
}

// Get the distance between two coordinates
function getDistance(startLong, startLat, endLong, endLat) {
  const dLong = endLong - startLong;
  const dLat = endLat - startLat;
  return Math.sqrt(dLong * dLong + dLat * dLat); //in meters
}

//Receive a number in seconds and return a string in the format hh:mm
function secondsToHms(seconds) {
  seconds = Number(seconds);
  let h = Math.floor(seconds / 3600);
  let m = Math.floor(seconds % 3600 / 60);

  let hDisplay = h.toString().padStart(2, '0');
  let mDisplay = m.toString().padStart(2, '0');

  return hDisplay + 'h' + mDisplay;
};


//Function to find a feature based on its attributes
function findFeature(nom_bateau, annee, serie, aller_retour) {
  return vectorSource.getFeatures().find(function (feature) {
    return feature.get('nom_bateau') === nom_bateau &&
      feature.get('annee') == annee &&
      feature.get('serie') === serie &&
      feature.get('aller_retour') === aller_retour;
  });
}

//Function to add a feature to selectedFeature in both directions (aller and retour) and its color
function addFeatureToSelectedList(feature) {
  let selIndex = selectedFeature.indexOf(feature);
  if (selIndex < 0) {
    //Check if the feature is aller or retour
    let aller_retour = feature.get('aller_retour');
    let nom_bateau = feature.get('nom_bateau');
    let annee = feature.get('annee');
    let serie = feature.get('serie');
    let color = getRandomColor();
    if (aller_retour === 'aller') {
      //add both features
      selectedFeature.push(feature, findFeature(nom_bateau, annee, serie, 'retour'));
      selectedColor.push(color, color);
    } else if (aller_retour === 'retour') {
      //add both features
      selectedFeature.push(findFeature(nom_bateau, annee, serie, 'aller'), feature);
      selectedColor.push(color, color);
    }

    //Set the style of the feature
    feature.setStyle(defaultStyles);

    //Hide or show attTablePlaceholder
    hideShowAttTablePlaceholder();

    //redraw the boats at time layer
    showBoatsAtTime(timeAfterStartSlider.value);
  }

  //If the list is not empty, activate the unselect button
  if (selectedFeature.length > 0) {
    unselectButton.disabled = false;
  }

}


//Function to remove a feature from selectedFeature in both directions (aller and retour) and its color
function removeFeatureFromSelectedList(feature) {
  let selIndex = selectedFeature.indexOf(feature);
  if (selIndex >= 0) {
    //Check if the feature is aller or retour
    let aller_retour = feature.get('aller_retour');
    if (aller_retour === 'aller') {
      //delete both features
      selectedFeature.splice(selIndex, 2);
      selectedColor.splice(selIndex, 2);
    } else if (aller_retour === 'retour') {
      //delete both features
      selectedFeature.splice(selIndex - 1, 2);
      selectedColor.splice(selIndex - 1, 2);
    }
    hoveredFeatures = [];

    //Reset the style of the feature
    feature.setStyle(defaultStyles);
    //Hide or show attTablePlaceholder
    hideShowAttTablePlaceholder();

    //redraw the boats at time layer
    showBoatsAtTime(timeAfterStartSlider.value);
  }

  //If the list is empty, disable the unselect button
  if (selectedFeature.length === 0) {
    unselectButton.disabled = true;
  }

}





/*
*Menu button to show or hide the menu
*/
//Listen for clicks on the menu button
[menuButton, appliedFiltersDiv].forEach(function (element) {
  element.addEventListener('click', function () {

    //If the first column of the wrapper width is 0, make it visible
    if (menuDiv.clientWidth == 0) {
      //show menuDiv
      page.style.gridTemplateColumns = '230px 6fr';
      //show menu items
      menuDiv.style.display = 'block';
      //Set menuButton to active
      menuButton.classList.add('is-active');
      //Hide the element with id appliedFiltersText
      appliedFiltersText.style.display = 'none';

    } else {
      page.style.gridTemplateColumns = '0px 6fr';
      menuDiv.style.display = 'none';
      menuButton.classList.remove('is-active');
      appliedFiltersText.style.display = 'block';
    }
    map.updateSize();
  });
});

/*
* Button to hide the mobile warning
*/
//Listen for clicks on the mobileDivButton button
mobileDivButton.addEventListener('click', function () {
  //Hide the mobileDiv
  mobileDiv.style.display = 'none';
});
